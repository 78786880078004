import React from 'react';
import { Link , StaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import { Image, Flex, Box } from 'rebass/styled-components';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import Banner from '../components/Banner';
import colors from '../../colors';

const disabledLinkStyles = {
  pointerEvents: 'none',
  cursor: 'default',
  color:colors.headingSecondary
}

const ImageCard = ({
  projectUrl,
  card,
}) => (

  projectUrl ? (
    <Link style={{color:colors.headingSecondary }} to={`/${projectUrl}`}>
      <Image height="80%" width={1} src={card.file.url} alt={card.title} />
      <p style={{textAlign:"center", fontSize:"1.5em"}}>
        {card.title}
      </p>
    </Link>
)
  : (
    <Link style={disabledLinkStyles} to="/">
      <Image
        width={1}
        src={card.file.url}
        alt={card.title} 
      />
      <p style={{textAlign:"center", fontSize:"1.5em"}}>
        {card.title}
        {' '}
        - Coming Soon
      </p>
    </Link>
)
);

ImageCard.propTypes = {
  projectUrl: PropTypes.string.isRequired,
  card: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
};

const Projects = () => (
  
  <StaticQuery
    query={graphql`
        query ProjectsQuery {
          contentfulAbout {
            projects {
              id
              name
              description
              projectUrl
              type
              card {
                title
                file {
                  url
                }
              }
            }
          }
          allContentfulProjectsBannerImage {
            nodes {
              image {
                file {
                  url
                }
                title
              }
            }
          }
        }
      `}
    render={({ contentfulAbout, allContentfulProjectsBannerImage }) => {
      const { image} = allContentfulProjectsBannerImage.nodes[0];
      return (
        <Fade>
          <Banner src={image.file.url} title={image.title} />
          <Section.Container>
            <Flex flexWrap='wrap'>
              {contentfulAbout.projects.map((p) => (
                <Box width={[1, 1/2]} key={p.id} p={4}>
                  <ImageCard {...p} />
                </Box>
            ))}
            </Flex>
          </Section.Container>
        </Fade>
      )}
    }
  />
);

export default Projects;
